<template>
  <b-row>
    <b-col cols="12">
      <divisoria-descricao
        class="mt-2"
        :titulo="'Informações sobre o CNAE'"
        :descricao="'Cadastro Nacional de Atividades Econômicas principal e secundários'"
      />
      <b-row>
        <b-col cols="12">
          <cnae-principal
            v-model="value.cnaePrincipal"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="CNAE secundários"
            label-for="cnaeSecundario"
          >
            <cnae-secundario
              id="cnaeSecundario"
              v-model="value.cnaesSecundarios"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'SecaoGeral',
  components: {
    CnaePrincipal: () => import('@pilar/components/cnae/Cnae.vue'),
    CnaeSecundario: () => import('@pilar/components/cnae-tabela/CnaeTabela.vue'),
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>
